import React, { ReactNode, useRef } from "react";
import { SnackbarProvider } from "notistack";
//
import Iconify from "./Iconify";

// ----------------------------------------------------------------------

type NotistackProviderProps = {
  children: ReactNode;
};

const NotistackProvider = ({ children }: NotistackProviderProps) => {
  const notistackRef = useRef(null);

  return (
    <>
      <SnackbarProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={50000}
        variant="success" // Set default variant
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        iconVariant={{
          info: <SnackbarIcon icon={"eva:info-fill"} color="info" />,
          success: (
            <SnackbarIcon
              icon={"eva:checkmark-circle-2-fill"}
              color="success"
            />
          ),
          warning: (
            <SnackbarIcon icon={"eva:alert-triangle-fill"} color="warning" />
          ),
          error: <SnackbarIcon icon={"eva:alert-circle-fill"} color="error" />,
        }}
      >
        {children}
      </SnackbarProvider>
    </>
  );
};

// ----------------------------------------------------------------------

type SnackbarIconProps = {
  icon: string;
  color: "primary" | "secondary" | "info" | "success" | "warning" | "error";
};

const SnackbarIcon = ({ icon, color }: SnackbarIconProps) => {
  return (
    <span
      style={{
        marginRight: 1.5,
        width: 40,
        height: 40,
        display: "flex",
        borderRadius: 1.5,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Iconify icon={icon} width={24} height={24} />
    </span>
  );
};

export default NotistackProvider;
