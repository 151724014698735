import type { ThemeConfig } from "antd";

const config: ThemeConfig = {
  token: {
    colorPrimary: "rgb(253, 214, 35)",
    // colorPrimary: "eb641f",
    colorLink: "rgb(253, 214, 35)",
  },
  components: {
    Button: {
      // colorPrimary: "rgb(253, 214, 35)",
      margin: 10,
      padding: 5,
      colorPrimary: "#eb641f",
      borderRadius: 30,
      algorithm: true,
    },
  },
};

export default config;
