// icons
import { Icon, IconifyIcon } from "@iconify/react";
import React, { ReactNode } from "react";

// ----------------------------------------------------------------------

type IconifyProps = {
  icon: IconifyIcon | string;
  sx?: object;
  width?: number;
  height?: number;
  children?: ReactNode;
};

const Iconify: React.FC<IconifyProps> = ({ icon, sx, children, ...other }) => {
  return (
    <div {...other}>
      <Icon icon={icon} />
      {children}
    </div>
  );
};
export default Iconify;
