/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/styles/index.scss";

import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { enqueueSnackbar } from "notistack";
import { ConfigProvider } from "antd";
import { isArrayBuffer } from "lodash/fp";
import { forEach, isArray } from "lodash";
import axios from "axios";

import NotistackProvider from "./src/components/NotistackProvider";
import themeConfig from "./src/constants/theme";

export const wrapPageElement = ({ element, props }: any) => {
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 300 * 1000,
        onError: (error: any) => {
          if (isArrayBuffer(error?.response?.data)) {
            const result = JSON.parse(
              new TextDecoder().decode(error?.response?.data),
            );
            enqueueSnackbar(result?.message || error.message, {
              variant: "error",
            });
          } else {
            enqueueSnackbar(error?.response?.data?.message || error.message, {
              variant: "error",
            });
          }
        },
      },
      mutations: {
        onError: (error: any) => {
          let errorMsg = error?.response?.data?.message;
          if (isArray(errorMsg)) {
            forEach(errorMsg, (msg) => {
              enqueueSnackbar(msg, {
                variant: "error",
              });
            });
          } else {
            enqueueSnackbar(error?.response?.data?.message || error.message, {
              variant: "error",
            });
          }
        },
      },
    },
  });
  axios.defaults.baseURL = process.env.GATSBY_API_URL;

  return (
    <QueryClientProvider client={client}>
      <ConfigProvider theme={themeConfig}>
        <NotistackProvider>{element}</NotistackProvider>
      </ConfigProvider>
    </QueryClientProvider>
  );
};
